import { RefObject, useEffect } from 'react';

/**
 * Used to avoid different browsers behaviours on autofocuses when hide/show content
 * @param ref
 */
export function useAutofocusOnMount(ref: RefObject<HTMLInputElement | null>) {
  useEffect(() => {
    if (ref.current) {
      ref.current.focus();
    }
  }, []);
}
