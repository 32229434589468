import React, { useRef, useState } from 'react';
import { Input } from '@ui/input';
import { Button, ButtonType } from '@ui/button';
import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { Id as ToastId } from 'react-toastify';
import { apiUrl1, authHTTPClient } from '@api/auth/auth-client';
import { hideToast, showUnexpectedError } from '@lib/toast';
import { useAutofocusOnMount } from '@lib/useAutoFocus';
import { useLoginContext } from './login.context';
import styles from './login.css';

export default function LoginActiveDirectory() {
  const { t } = useTranslation();
  const [customerId, setCustomerId] = useState('');
  const { redirectDestination } = useLoginContext();
  const customerIdRef = useRef<HTMLInputElement>(null);

  const currentToast = useRef<ToastId>('');

  useAutofocusOnMount(customerIdRef);

  async function onSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();

    hideToast(currentToast.current);

    try {
      const basePath = '/auth/';

      const redirectParam = redirectDestination ? `?dest=${redirectDestination}` : '';
      const successRedirectUrl = encodeURIComponent(
        window.location.origin +
          basePath +
          'login/active-directory-callback/success' +
          redirectParam
      );
      const errorRedirectUrl = encodeURIComponent(
        window.location.origin + basePath + 'login/active-directory-callback/error' + redirectParam
      );

      const clientType = 'WebPortal';
      const params = `?successRedirectUrl=${successRedirectUrl}&errorRedirectUrl=${errorRedirectUrl}&clientType=${clientType}`;

      if (authHTTPClient.defaults.baseURL) {
        const url =
          authHTTPClient.defaults.baseURL +
          `${apiUrl1}/auth/external/${customerId}/signin` +
          params;

        window.location.replace(url);
        return;
      }

      throw new Error('Business logic error. baseURL should be defined');
    } catch (e) {
      const error = e as AxiosError;

      currentToast.current = showUnexpectedError(error);
    }
  }

  return (
    <div className={styles.content}>
      <h4>{t('login:signIn')}</h4>
      <form className={styles.loginForm} onSubmit={onSubmit}>
        <Input
          ref={customerIdRef}
          name={'customerId'}
          cy-id="customerId"
          placeholder={t('login:customerId')}
          value={customerId}
          onChange={(event: any) => setCustomerId(event.target.value)}
        />
        <Button
          disabled={!customerId}
          value={t('login:logIn')}
          type={ButtonType.submit}
          style={{ marginTop: '15px' }}
          cy-id="submitADForm"
        />
      </form>
    </div>
  );
}
